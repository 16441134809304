<script lang="ts" setup>
import { filename } from 'pathe/utils'
import { useUserStore, useUserProfileStore } from '@/store/userStore'
const { locale,t } = useI18n();
const storeUserProfile = useUserProfileStore()
const router = useRouter()
const store = useUserStore()
const localePath = useLocalePath()

const glob = import.meta.glob('~/assets/images/*.svg', { eager: true })
const images = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value.default]))

// Reactive links with translations
const links = computed(() => [
  { to: '/dashboard', img: 'dashboard', title: t('control_panel') },
  { to: '/dashboard/profile', img: 'profile', title: t('profile') },
  { to: '/dashboard/vehicles', img: 'vehicle', title: t('my_vehicle') },
  { to: '/dashboard/inspections', img: 'inspection', title: t('my_inspection') },
  { to: '/evaluations', img: 'eva', title: t('my_evaluation') },
  { to: '/dashboard/warranties', img: 'warranties', title: t('my_warranty') },
  { to: '/dashboard/user-scan-list', img: 'qr-code', title: t('qr_code_history') },
  { to: '/dashboard/transaction', img: 'transaction', title: t('my_transaction') },
  { to: '/dashboard/wallet', img: 'wallet2', title: t('my_wallet') },
  { to: '/dashboard/coupons', img: 'coupons', title: t('my_coupons') },
  { to: '/branches', img: 'branches', title: t('branches') },
]);

const links2  = computed(() => [
  { to: '/contact', img: 'contact', title:  t('contact_us') },
  // { to: '/', img: 'settings', title: 'الإعدادات' },
  // { to: '/', img: 'moon', title: 'Dark Mode' },
])

async function handleLogout() {
  try {
    store.removeUserFromLocalStorage()
    storeUserProfile.removeUserProfileFromLocalStorage()
    router.push(localePath('/'))
  } catch (error) {
    console.log(error)
  }
}
</script>

<template>
  <aside class="sidebar">
    <div class="fs-12 fw-600 text-puprlpeShade mb-3 opacity-40 sidebar__group-title">{{ $t('main_menu') }}</div>
    <ul class="mb-4">
      <AppSideBarItem v-for="item in links" :key="item.title" v-bind="item" :img="images[item.img]" />
    </ul>
    <div class="fs-12 fw-600 text-puprlpeShade opacity-40 mb-3 sidebar__group-title">{{ $t('preferences') }}</div>
    <ul>
      <AppSideBarItem v-for="item in links2" :key="item.title" v-bind="item" :img="images[item.img]" />
      <AppSideBarItem :title="$t('logout')" :img="images['logout']" @click="handleLogout" />
    </ul>
  </aside>
</template>

<style lang="css" scoped>
.sidebar {
  font-family: 'UniversNext';
  background: #fff;
  border-right: 1px solid #f3f5f7;
  width: 100%;
  padding: 30px 20px;
  height: 100%;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebar .sidebar__group-title {
  display: flex;
  align-items: center;
  font-family: 'UniversNext', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
  letter-spacing: -0.02em;
  color: #94a7cb;
}
</style>
